/* CSS for isf core */
@import "utils.less";

#head-wrapper,
#globalnav,
#footer-wrapper {
  background-color: @color-core !important;
}

a {
  color: @color-link-core;
}
.backgroundOnFocusHoverUnderlinedText(@color-link-core);

.add-comment-header,
#comments-header-left a,
#main *:not(.vrtx-messages) > .vrtx-resource a.vrtx-title,
.vrtx-search-results-container div.vrtx-search-results .result h2 a,
.grid-container .vrtx-frontpage-box h2 a {
  color: @color-link-core;
  &:hover,
  &:focus {
    color: @color-link-core;
  }
}

.not-for-ansatte {
  .vrtx-image-listing-include,
  #head-wrapper #head {
    #header {
      a#logo {
        .font-size(32);
      }
    }
  }

  #head-wrapper {
    #head {
      #header {
        a#logo {
          top: 72px;
          .font-size(32);
          font-weight: 400;
        }
        .header-samarbeid {
          color: @color-light;
          a {
            color: @color-light;
            border-bottom: 1px solid @color-light;
            &:hover,
            &:focus {
              background-color: @color-light;
              color: @color-link-core;
            }
          }
        }
      }
      .head-menu .vrtx-dropdown-wrapper ul li a {
        color: @color-link-core !important;
      }
    }
  }

  button:not(.comment-delete-button):not(#vrtx-comments-delete-all):not(.red):not(.program-create-print):not(.program-create-send-dialog):not(.vrtx-program-read-more):not(.vrtx-program-how-search):not([class^="admin-frontpage"]):not([class^="admin-box-row"]):not([class^="vrtx-frontpage-box"]):not([class^="change-positions-in-row"]):not([class^="vrtx-edit-"]):not(.undo):not(.x-cancel),
  button:not(.comment-delete-button):not(#vrtx-commments-delete-all):not(.red)
    input[type="button"]:not(.red),
  input[type="submit"]:not(.submit-comment-button):not(.red),
  input[type="cancel"]:not(.red),
  .header-search-expand,
  button:not([class^="admin-frontpage"]):not([class^="change-positions-in-row"]) {
    height: 52px;
    background-color: @color-core !important;
    &:focus,
    &:hover {
      background-color: @color-neutral !important;
    }
  }
  &.header-search-collapsable .header-search-expand {
    background: #737677
      url("../images/magnifier-core.svg") no-repeat 0px
      17px;
    background-size: 15px auto;
  }

  #footer-wrapper .footer-logo img {
    max-width: 211px;
  }
  &.en #footer-wrapper .footer-logo img {
    max-width: 235px;
  }
  .footer-logo a {
    line-height: 0;
  }
}

#main {
  .navigation-links li a {
    &:after {
      background: transparent
        url("../images/arrow-forward-green.svg")
        no-repeat scroll 0 0 / auto 100%;
    }
    &.active {
      color: @color-light;
      background-color: @color-core !important;

      &:after {
        background-image: url(../images/arrow-forward-white.svg);
      }
    }
    &:hover,
    &:focus {
      color: @color-light;
      background-color: @color-core !important;
    }
  }
  .button:not([class^="admin-frontpage"]):not([class^="admin-box-row"]):not([class^="vrtx-frontpage-box"]):not([class^="change-positions-in-row"]):not([class^="vrtx-edit-"]):not(.undo):not(.x-cancel) {
    background-color: @color-core !important;
    &:hover,
    &:focus {
      background-color: @color-neutral !important;
    }
  }
}

.ui-tabs.ui-widget.ui-widget-content {
  .ui-tabs-nav li {
    &.ui-state-active,
    &.ui-state-hover {
      background: @color-core;
    }
  }
}

#head-wrapper #globalnav ul li {
  a,
  a:hover,
  a:focus,
  &.vrtx-active-item a,
  &.vrtx-active-item a:hover,
  &.vrtx-active-item a:focus {
    background-color: @color-core;
  }
}

.ui-tabs.ui-widget.ui-widget-content p.vrtx-more-external-publications a,
#vrtx-listing-completed-ongoing a {
  background-image: url("../images/arrow-forward-green-bullet.svg") !important;
}
a.vrtx-more,
div.vrtx-more a,
a.all-messages,
a.all-comments,
.footer > .vrtx-search-scope a,
a.more-url {
  &::before {
    background-image: url("../images/arrow-forward-green-bullet.svg") !important;
  }
}

.ui-accordion.ui-widget {
  .ui-accordion-header .ui-icon {
    background-image: url("../images/icon-chevron-right-green.svg");
  }
  .ui-accordion-header.ui-accordion-header-active .ui-icon {
    background-image: url("../images/icon-chevron-down-green.svg");
  }
}

.js .toggle-more {
  & > {
    h2:before,
    h2:before,
    h3:before,
    h4:before,
    h5:before,
    h6:before {
      background-image: url("../images/icon-chevron-right-green.svg");
    }
  }

  &.toggle-more-active > {
    h2:before,
    h3:before,
    h4:before,
    h5:before,
    h6:before {
      background-image: url("../images/icon-chevron-down-green.svg");
    }
  }
}

.not-for-ansatte #footer-wrapper {
  .footer-logo img {
    height: fit-content;
    width: fit-content;
  }
  .col-1-2, .col-2-5 {
    .content {
      margin-top: 5px;
    }
  }
}
.footer-bottom-row {
  background-color: @color-core--light;
  p {
    color: @color-light;
  }
}

@media only screen and (max-width: 16cm) and (orientation: portrait),
  only screen and (max-width: 19cm) and (orientation: landscape) {
  .not-for-ansatte {
    #head-wrapper {
      background: none @color-core;
      #head {
        #header {
          a#logo {
            line-height: 1.22;
          }
          .header-samarbeid {
            position: static;
            margin: 10px 0 5px 0;
            .font-size(16);
          }
        }
      }
    }
    #menu-wrapper .menu {
      li {
        background-color: @color-core;

        &.active {
          background-color: darken(@color-core, 4%);
        }
        a {
          &.toggle-navigation .icon {
            background-image: url("../images/nav-icon-core.svg") !important;
          }
          &.toggle-search .icon {
            background-image: url("../images/search-icon-core.svg") !important;
          }
        }
      }
    }
    #main {
      .navigation-links li {
        a {
          background: transparent
            url("../images/arrow-forward-green.svg")
            no-repeat scroll 3px 4px / auto 100%;
          background-size: 16px 16px !important;
          &::after {
            background: none !important;
          }
          &:hover,
          &:focus {
            background-color: transparent !important;
            color: @color-link-core !important;
            text-decoration: underline;
          }
        }
      }
    }
  }
}

@media print {
  .not-for-ansatte #head-wrapper {
    background: @color-core !important;
  }
}
